const glEngine = (function()
{
	const BG_COLOUR = [255/255, 255/255, 255/255, 1.0];

	let canvas;
	let gl;
	let model;
	let startTimestamp;
	let isDebugMode;

	let programLocations = {
		floatTimeUniformLocation: 0,
		vec2SeedUniformLocation: 0,
		vec2GraphOriginUniformLocation: 0,
		vec2GraphScaleUniformLocation: 0,
		samplerTexUniformLocation: 0,
	}

	const VERTEX_SHADER_SOURCE = `
		precision mediump float;
		uniform vec2 uGraphOrigin;
		uniform vec2 uGraphScale;
		attribute vec3 aVertPosition;
		varying vec2 vUV;

		void main()
		{
			gl_Position = vec4(aVertPosition, 1.0);
			float uvX =  aVertPosition.x * uGraphScale.x * 0.5;
			float uvY = aVertPosition.y * uGraphScale.y * 0.5;
			vUV = uGraphOrigin + vec2(uvX, uvY);
		}
	`;
	const FRAGMENT_SHADER_SOURCE = `
		precision mediump float;
		const int ITERATIONS = 100;
		uniform sampler2D uSampler;
		uniform float uTime;
		uniform vec2 uSeed;
		varying vec2 vUV;

		void main(void)
		{
			//julia set
			vec2 c = uSeed;
			vec2 z = vUV;
			//mandelbrot set
			//vec2 c = vUV;
			//vec2 z = vec2(0.0);
			int iter;
			for (int i = 0; i < ITERATIONS; i++)
			{
				iter = i;
				z = vec2(z.x * z.x - z.y * z.y, 2.0 * z.x * z.y) + c;
				if ((z.x * z.x + z.y * z.y) > 4.0)
				{
					break;
				}
			};
			float uvX = (3.0 * float(iter) / float(ITERATIONS)) + uTime / 10.0;
			gl_FragColor = texture2D(uSampler, vec2(uvX, 0.0));
		}
	`;
    
    function init(glCanvas, modelData, textureSource, debugMode)
    {
		canvas = glCanvas;
		gl = canvas.getContext('webgl');
		if (!gl) return false;

		model = modelData;
		isDebugMode = debugMode;
		startTimestamp = (Date.now() / 1000);

		//gl.enable(gl.DEPTH_TEST);
		gl.enable(gl.CULL_FACE);
        gl.cullFace(gl.BACK);
		gl.frontFace(gl.CCW);

		let positionsBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionsBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(model.positions), gl.DYNAMIC_DRAW);

		let indicesBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indicesBuffer);
		gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(model.indices), gl.STATIC_DRAW);

        /*=========================Shaders========================*/
		let vertexShader = makeShader(VERTEX_SHADER_SOURCE , gl.VERTEX_SHADER);
		let fragmentShader = makeShader(FRAGMENT_SHADER_SOURCE, gl.FRAGMENT_SHADER);
        let shaderProgram = gl.createProgram();
        gl.attachShader(shaderProgram, vertexShader); 
        gl.attachShader(shaderProgram, fragmentShader);
        gl.linkProgram(shaderProgram);
		gl.useProgram(shaderProgram);
		programLocations.floatTimeUniformLocation = gl.getUniformLocation(shaderProgram, 'uTime');
		programLocations.vec2SeedUniformLocation = gl.getUniformLocation(shaderProgram, 'uSeed');
		programLocations.vec2GraphOriginUniformLocation = gl.getUniformLocation(shaderProgram, 'uGraphOrigin');
		programLocations.vec2GraphScaleUniformLocation = gl.getUniformLocation(shaderProgram, 'uGraphScale');
		programLocations.samplerTexUniformLocation = gl.getUniformLocation(shaderProgram, 'uSampler');

		/*=========================Textures========================*/
		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, makeTexture(gl, textureSource));
		gl.uniform1i(programLocations.samplerTexUniformLocation, 0);

        /*======== Associating shaders to buffer objects ========*/
        gl.bindBuffer(gl.ARRAY_BUFFER, positionsBuffer);
        let posAttrib = gl.getAttribLocation(shaderProgram, 'aVertPosition');
		gl.vertexAttribPointer(posAttrib, 3, gl.FLOAT, false, 0, 0);
		gl.enableVertexAttribArray(posAttrib);

		return true;
	}
	//
	// Render loop
	//
	function render(seedX, seedY, originX, originY, rangeX, rangeY)
	{
		gl.uniform1f(programLocations.floatTimeUniformLocation, (Date.now() / 1000.0) - startTimestamp);
		gl.uniform2f(programLocations.vec2SeedUniformLocation, seedX, seedY);
		gl.uniform2f(programLocations.vec2GraphOriginUniformLocation, originX, originY);
		gl.uniform2f(programLocations.vec2GraphScaleUniformLocation, rangeX, rangeY);
		gl.clearColor(...BG_COLOUR);
		gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
		gl.drawElements(gl.TRIANGLES, model.indices.length, gl.UNSIGNED_SHORT, 0);
	}

	function makeShader(source, type)
	{
		let shader = gl.createShader(type);
		gl.shaderSource(shader, source);
		gl.compileShader(shader);
        if (isDebugMode == true && !gl.getShaderParameter(shader, gl.COMPILE_STATUS))
        {
			console.error('ERROR compiling ' + type +' shader!', gl.getShaderInfoLog(shader));
			return;
        }
        return shader;
	}

	function makeTexture(gl, url)
	{
		const texture = gl.createTexture();
		gl.bindTexture(gl.TEXTURE_2D, texture);
		//use generated 1x1 texture while waiting for image to load
		const level = 0;
		const internalFormat = gl.RGBA;
		const width = 1;
		const height = 1;
		const border = 0;
		const srcFormat = gl.RGBA;
		const srcType = gl.UNSIGNED_BYTE;
		const pixel = new Uint8Array([255,255,255,255]);
		gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
					  width, height, border, srcFormat, srcType,
					  pixel);
		const image = new Image();
		image.onload = function() {
			gl.bindTexture(gl.TEXTURE_2D, texture);
			gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);
			gl.generateMipmap(gl.TEXTURE_2D);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
		    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
			//console.log('IMAGE LOADED');
		};
		image.src = url;
		return texture;
	  }

	function setSize(w, h)
	{
		let scale = window.devicePixelRatio;
		let cw = Math.floor(w * scale);
		let ch = Math.floor(h * scale);
		canvas.width = cw;
		canvas.height = ch;
		gl.viewport(0, 0, cw, ch);
	}

	return {
        init: init,
        render: render,
		setSize: setSize,
	}
})();