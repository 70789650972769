'use strict';

window.onload = function() {
	document.getElementById('contact').href = 'mailto:hello@leeburrows.com';
    app();
};

function app()
{
	let canvas = document.getElementById('theCanvas');
	let success = glEngine.init(canvas, makeModel(), 'img/texture.png', true);
	if (success == false) return;

	//let mouseX = 0, mouseY = 0;
	window.onresize = doResize;
	window.onorientationchange = doResize;
	//document.onmousemove = doMouseMove;
	doResize();
	requestAnimationFrame(loop);

	function loop()
	{
		let RADIUS = 0.75;
		let t = 0.1 * Date.now() / 1000.0;
		let seedX = /* mouseX * 0.5 +  */RADIUS * Math.cos(t);
		let seedY = /* mouseY * 0.5 +  */RADIUS * Math.sin(t);
		let aspect = canvas.width / canvas.height;
		let originX = 0;
		let originY = 0;
		let rangeX = 4;
		let rangeY = 4;
		//if portrait, let rangeY fill height
		//if landscape, let rangeX fill width
		if (aspect < 1)
		{
			rangeX *= aspect;
		}
		else
		{
			rangeY /= aspect;
		}
		glEngine.render(seedX, seedY, originX, originY, rangeX, rangeY);
		requestAnimationFrame(loop);
	}

	function doResize()
	{
		glEngine.setSize(window.innerWidth, window.innerHeight);
	}

	// function doMouseMove(e)
	// {
	// 	//remap screen coords to -1...1
	// 	mouseX = (e.clientX / window.innerWidth) * 2 - 1;
	// 	mouseY = (e.clientY / window.innerHeight) * 2 - 1;
	// }

	function makeModel()
	{
		let positions = [];
		let indices = [];

		positions.push(-1, -1, 0);
		positions.push(1, -1, 0);
		positions.push(-1, 1, 0);
		positions.push(1, 1, 0);

		indices.push(0);
		indices.push(1);
		indices.push(2);
		indices.push(2);
		indices.push(1);
		indices.push(3);

		return {
			positions: positions,
			indices: indices,
		}
	}
}